import React, { Component } from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
} from "react-stripe-elements";
import withSizes from "react-sizes";

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: "#424770",
				width: "50% !important",
				letterSpacing: "0.025em",
				border: "1px solid #383838",
				fontFamily: "Nunito Sans, sans-serif",
				"::placeholder": {
					color: "#aab7c4"
				},
				padding
			},
			invalid: {
				color: "#9e2146"
			}
		}
	};
};

class CheckoutForm extends Component {
	state = {
		error: ""
	};

	handleChange = ({ error }) => {
		if (error) {
			this.setState({ errorMessage: error.message });
		}
	};

	handleSubmit = evt => {
		evt.preventDefault();
		if (this.props.stripe) {
			this.props.stripe.createToken().then(this.props.submit);
		} else {
			console.log("Stripe.js hasn't loaded yet.");
		}
	};

	// async submit(ev) {
	//     if (!this.state.loading){
	//         this.setState(
	//         {
	//             loading: true,
	//         })
	//     }
	//     let userData = await db.getUserMeta();
	//     let username = userData.firstName+' '+userData.lastName;
	//     let {token} = await this.props.stripe.createToken({name: username, type: 'card'});
	//     if (token){
	//         if (token.error){
	//             //return error
	//             let errorStr = 'Please fill in correct card details, or try again later.'
	//             this.setState({error: errorStr, snackBarOpen: true, loading: false})
	//         }
	//         else{
	//             let res = await fb.createStripeCustomer(token.id);
	//             console.log(res);
	//             if (res.data===true){
	//                 let str = "Successfully updated card details."
	//                 this.setState({error: str, snackBarOpen: true, loading: false})
	//                 this.props.handleClose()
	//             }
	//             else{
	//                 let errorStr = 'Please fill in correct card details, or try again later.'
	//                 this.setState({error: errorStr, snackBarOpen: true, loading: false})
	//             }
	//         }
	//     }
	//     else{
	//         let errorStr = 'Please fill in correct card details, or try again later.'
	//         this.setState({error: errorStr, snackBarOpen: true, loading: false})
	//     }
	// }

	render() {
		return (
			<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="split-form" style={{ textAlign: "left" }}>
					<div>
						<label style={{ color: "#929292" }}>
							Card number
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<div
									style={{
										width: "55%",
										display: "inline-block"
									}}
								>
									{" "}
									<CardNumberElement
										{...createOptions()}
										onChange={this.handleChange}
									/>
								</div>
								<div
									style={{
										width: "45%",
										display: "inline-block"
									}}
								>
									<img
										src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/creditCards.png"
										alt="cards"
										style={{
											maxWidth: this.props.isMobile
												? "115px"
												: "140px",
											margin: this.props.isMobile
												? null
												: "0em 0.5em",
											verticalAlign: "bottom"
										}}
									/>
								</div>
							</div>
						</label>
					</div>
					<div
						style={{
							width: "49%",
							display: "inline-block",
							marginRight: "2%"
						}}
					>
						<label style={{ color: "#929292" }}>
							Expiration date
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<CardExpiryElement
									{...createOptions()}
									onChange={this.handleChange}
								/>
							</div>
						</label>
					</div>
					<div
						style={{
							width: "49%",
							display: "inline-block"
						}}
					>
						<label style={{ color: "#929292" }}>
							CVC
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<CardCVCElement
									{...createOptions()}
									onChange={this.handleChange}
								/>
							</div>
						</label>
					</div>
				</div>
			</form>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(injectStripe(CheckoutForm));
