import React, { Component } from "react";
import { NewSettingsModal } from "../components";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import { auth } from "../firebase/firebase";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Divider } from "@material-ui/core";

const styles = theme => ({
	root: {
		maxWidth: 500,
		margin: "1em auto",
		marginTop: "8vh",
		fontFamily: "Nunito Sans, sans-serif",
		padding: "2em"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary
	},
	media: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: "cover"
	},
	button: {
		margin: "0px",
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	}
});

class Settings extends Component {
	state = {
		userName: "",
		modalOpen: false,
		loading: true,
		currencyListString: "-",
		openBilling: false,
		openPayment: false,
		openDelete: false,
		company: "",
		line1: "",
		line2: "",
		city: "",
		address_zip: "",
		state: "",
		state_code: 0,
		country: "",
		country_code: 0,
		openEmail: false,
		email: auth.currentUser.email
	};

	componentDidMount() {
		db.getUserName().then(snapshot => {
			this.setState({
				userName: snapshot
			});
		});

		db.getUserMeta().then(snapshot => {
			this.setState({
				email: snapshot.email
			});
			if (snapshot.billingAddress) {
				let company = "";
				if (
					snapshot.billingAddress.company !== "undefined" &&
					snapshot.billingAddress.company !== null
				) {
					company = snapshot.billingAddress.company;
				}
				let line2 = "";
				if (
					snapshot.billingAddress.line2 !== "undefined" &&
					snapshot.billingAddress.line2 !== null
				) {
					line2 = snapshot.billingAddress.line2;
				}
				let state_code = "";
				if (
					snapshot.billingAddress.state_code !== "undefined" &&
					snapshot.billingAddress.state_code !== null
				) {
					state_code = snapshot.billingAddress.state_code;
				}
				let country_code = "";
				if (
					snapshot.billingAddress.country_code !== "undefined" &&
					snapshot.billingAddress.country_code !== null
				) {
					country_code = snapshot.billingAddress.country_code;
				}
				this.setState({
					company: company,
					line1: snapshot.billingAddress.line1,
					line2: line2,
					city: snapshot.billingAddress.city,
					address_zip: snapshot.billingAddress.address_zip,
					state: snapshot.billingAddress.state,
					state_code: state_code,
					country: snapshot.billingAddress.country,
					country_code: country_code,
					loading: false
				});
			} else {
				this.setState({
					loading: false
				});
			}
			return snapshot;
		});
	}

	handleOpen = value => {
		if (value === "Payment") {
			this.setState({
				openPayment: true
			});
		} else if (value === "Billing") {
			this.setState({
				openBilling: true
			});
		}
	};
	handleClose = () => {
		db.getUserMeta().then(snapshot => {
			if (snapshot.billingAddress) {
				let company = "";
				if (
					snapshot.billingAddress.company !== "undefined" &&
					snapshot.billingAddress.company !== null
				) {
					company = snapshot.billingAddress.company;
				}
				let line2 = "";
				if (
					snapshot.billingAddress.line2 !== "undefined" &&
					snapshot.billingAddress.line2 !== null
				) {
					line2 = snapshot.billingAddress.line2;
				}
				let state_code = "";
				if (
					snapshot.billingAddress.state_code !== "undefined" &&
					snapshot.billingAddress.state_code !== null
				) {
					state_code = snapshot.billingAddress.state_code;
				}
				let country_code = "";
				if (
					snapshot.billingAddress.country_code !== "undefined" &&
					snapshot.billingAddress.country_code !== null
				) {
					country_code = snapshot.billingAddress.country_code;
				}
				this.setState({
					company: company,
					line1: snapshot.billingAddress.line1,
					line2: line2,
					city: snapshot.billingAddress.city,
					address_zip: snapshot.billingAddress.address_zip,
					state: snapshot.billingAddress.state,
					state_code: state_code,
					country: snapshot.billingAddress.country,
					country_code: country_code,
					loading: false
				});
			}
			return snapshot;
		});
		this.setState({
			openBilling: false,
			openPayment: false,
			openDelete: false
		});
	};

	handleClickOpenEmail = () => {
		this.setState({ openEmail: true });
	};
	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleCloseEmail = type => {
		console.log(this.state.email);
		if (type === 1) db.updateEmail(this.state.email);
		this.setState({ openEmail: false });
	};

	render() {
		const { classes, isMobile } = this.props;
		const {
			loading,
			// company,
			line1,
			line2,
			city,
			address_zip,
			state,
			// state_code,
			country
			// country_code
		} = this.state;
		var billingAddress = `${line1},`;
		if (line2 !== "" && line2 !== undefined) {
			billingAddress += ` ${line2},`;
		}
		billingAddress += ` ${city}, ${state} ${address_zip}, ${country}`;
		if (line1 === "") billingAddress = "Please add billing address";
		var snapshot = {
			type: "Mastercard",
			last4: "1234",
			month: 9,
			year: 2020
		};
		// below still beeds to be used
		var cardString = `${snapshot.type}\u00A0\u00A0\u00A0\u00A0 •••• ${snapshot.last4}`;
		let month = snapshot.month;
		if (month < 10) {
			cardString =
				cardString +
				`\u00A0\u00A0\u00A0\u00A0 0${month}/${snapshot.year}`;
		} else {
			cardString =
				cardString +
				`\u00A0\u00A0\u00A0\u00A0 ${month}/${snapshot.year}`;
		}

		return (
			<div className={classes.root}>
				<h1>
					<span
						style={{
							color: "#35B2FF",
							fontWeight: 900
						}}
					>
						ACCOUNT
					</span>
					<span style={{ fontWeight: 900 }}> SETTINGS</span>
				</h1>
				<br />
				{loading ? (
					<Lottie
						height={200}
						width={200}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
				) : (
					<div>
						<p>
							Name
							<br />
							<span style={{ fontWeight: 700 }}>
								{this.state.userName}
							</span>
							{/* <IconButton>
                <Edit />
              </IconButton> */}
						</p>
						<p>
							Email
							<br />
							<span style={{ fontWeight: 700 }}>
								{this.state.email}
							</span>
							<IconButton onClick={this.handleClickOpenEmail}>
								<Edit />
							</IconButton>
							<Dialog
								open={this.state.openEmail}
								onClose={this.handleCloseEmail}
							>
								<DialogContent>
									Enter new email:
									<TextField
										autoFocus
										margin="dense"
										id="email"
										type="email"
										value={this.state.email}
										onChange={this.handleChange("email")}
										fullWidth
									/>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={() => this.handleCloseEmail(0)}
										color="primary"
									>
										Cancel
									</Button>
									<Button
										onClick={() => this.handleCloseEmail(1)}
										color="primary"
									>
										Update
									</Button>
								</DialogActions>
							</Dialog>
						</p>
						<Grid container spacing={1}>
							<Grid item xs={8}>
								<p>
									Billing Address
									<br />{" "}
									{!_.isEmpty(billingAddress) ? (
										<span style={{ fontWeight: 700 }}>
											{billingAddress}
										</span>
									) : (
										"Enter your billing address"
									)}
								</p>
							</Grid>
							<Grid item xs={2}>
								<p>
									<IconButton
										onClick={() =>
											this.handleOpen("Billing")
										}
									>
										<Edit />
									</IconButton>
								</p>
							</Grid>
							{/* <Grid item xs={8}>
                <p>
                  Payment
                  <br />{" "}
                  {!_.isEmpty(cardString) ? (
                    <span style={{ fontWeight: 700 }}>{cardString}</span>
                  ) : (
                    "Enter your payment details"
                  )}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>
                  <IconButton onClick={() => this.handleOpen("Payment")}>
                    <Edit />
                  </IconButton>
                </p>
              </Grid> */}
						</Grid>
					</div>
				)}
				<br />
				<Elements>
					<NewSettingsModal
						isMobile={isMobile}
						openBilling={this.state.openBilling}
						openPayment={this.state.openPayment}
						openDelete={this.state.openDelete}
						handleClose={this.handleClose}
					/>
				</Elements>
				{/* <Typography
          variant="h5"
          style={{ fontFamily: "Nunito Sans, sans-serif" }}
        >
          Payment and Address
        </Typography>
        <br />
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={isMobile ? 12 : 6}>
            <Card>
              <CardActionArea
                onClick={() => this.handleOpen("Payment")}
                style={{ width: "100%", height: "100%" }}
                className={classes.media}
              >
                <CardContent>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={2}>
                      <Payment
                        style={{
                          fontSize: "3em",
                          paddingLeft: "20%",
                          paddingTop: "20%"
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={10}>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Nunito Sans, sans-serif"
                        }}
                      >
                        Update Payment
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: "Nunito Sans, sans-serif"
                        }}
                      >
                        Update Stripe settings
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item={true} xs={isMobile ? 12 : 6}>
            <Card>
              <CardActionArea
                onClick={() => this.handleOpen("Billing")}
                style={{ width: "100%", height: "100%" }}
                className={classes.media}
              >
                <CardContent>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={2}>
                      <LocalShipping
                        style={{
                          fontSize: "3em",
                          paddingLeft: "20%",
                          paddingTop: "20%"
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={10}>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Nunito Sans, sans-serif"
                        }}
                      >
                        Update Billing Address
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: "Nunito Sans, sans-serif"
                        }}
                      >
                        Update Billing Information
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <br /> */}
				<br />
				<Link to="/" style={{ textDecoration: "none" }}>
					<Button
						variant="contained"
						className={classes.button}
						style={{ fontWeight: "500" }}
					>
						Back to dashboard
					</Button>
				</Link>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Divider />
				<br />
				<Button
					variant="contained"
					className={classes.button}
					style={{ fontWeight: "500", textColor: "red !important" }}
					onClick={() => this.setState({ openDelete: true })}
				>
					Delete Account
				</Button>
			</div>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Settings));
