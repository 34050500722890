import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

export default function ErrorSnackbar(props) {
	const [state, setState] = useState({
		vertical: "top",
		horizontal: "center",
		Transition: Slide
	});

	const { vertical, horizontal } = state;

	const errorMessages = [
		{
			type: 0,
			message: "Oops! Something went wrong! :("
		},
		{
			type: 1,
			message: "Please ensure terms and conditions are checked."
		},
		{
			type: 2,
			message: "Please ensure all fields are inputted."
		},
		{
			type: 3,
			message:
				"Your email already belongs to an account. Sign in or please use a different email."
		},
		{
			type: 4,
			message: "Please ensure your email is valid."
		},
		{
			type: 5,
			message: "Operation not allowed."
		},
		{
			type: 6,
			message: "Please ensure your password contains 6 or more characters."
		},
		{
			type: 7,
			message: "Your account is disabled, please contact hello@enteroffer.com to rectify."
		},
		{
			type: 8,
			message: "Please ensure your email and password are correct."
		}
	];

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				key={`${vertical},${horizontal}`}
				open={props.open}
				autoHideDuration={5000}
				TransitionComponent={state.Transition}
				onClose={() => props.parentCallbackCloseError()}
				ContentProps={{
					"aria-describedby": "message-id"
				}}
				message={
					<span id="message-id">
						{props.errorType !== undefined &&
						props.errorType !== null &&
						errorMessages[props.errorType] !== undefined &&
						errorMessages[props.errorType] !== null
							? errorMessages[props.errorType].message
							: errorMessages[0].message}
					</span>
				}
			/>
		</div>
	);
}
