import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
var liveTesting = false
var userConfig = {
  apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
  authDomain: "oaklas-user.firebaseapp.com",
  databaseURL: "https://oaklas-user.firebaseio.com",
  projectId: "oaklas-user",
  storageBucket: "oaklas-user.appspot.com",
  messagingSenderId: "282663992239"
}
var retailerConfig = {
  apiKey: 'AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0',
  authDomain: 'oaklas-bookmark-tool.firebaseapp.com',
  databaseURL: 'https://oaklas-bookmark-tool.firebaseio.com',
  projectId: 'oaklas-bookmark-tool',
  storageBucket: 'oaklas-bookmark-tool.appspot.com',
  messagingSenderId: '650641977232'
};
if (liveTesting){
  userConfig = {
    apiKey: "AIzaSyAhXdBRz-UN5nM9W0Rq1zVhy272ldn3ZAE",
    authDomain: "oaklas-user-dev.firebaseapp.com",
    databaseURL: "https://oaklas-user-dev.firebaseio.com",
    projectId: "oaklas-user-dev",
    storageBucket: "oaklas-user-dev.appspot.com",
    messagingSenderId: "1043319675732"
  };
  retailerConfig = {
    apiKey: 'AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE',
    authDomain: 'oaklas-retailer-dev.firebaseapp.com',
    databaseURL: 'https://oaklas-retailer-dev.firebaseio.com',
    projectId: 'oaklas-retailer-dev',
    storageBucket: 'oaklas-retailer-dev.appspot.com',
    messagingSenderId: '975196738296'
  };
}

// const config = process.env.NODE_ENV === 'production'
//   ? prodConfig
//   : devConfig
//
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }

const userFB = firebase.initializeApp(userConfig)
const retailerFB = firebase.initializeApp(retailerConfig, 'other')

const retailerDB = retailerFB.database()
const userDB = userFB.database()
const auth = userFB.auth()
const retailerStorage = retailerFB.storage()

export {
  firebase,
  auth,
  retailerDB,
  retailerStorage,
  userDB,
  userFB
}
