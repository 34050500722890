import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import _ from "lodash";
import withSizes from "react-sizes";
import Expand from "@material-ui/icons/MoreHoriz";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import { SmallGrid, Receipt } from "./index";
var liveTesting = false;

const styles = theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "unset",
		backgroundColor: "#ffffff"
	},
	gridList: {
		display: "flex"
		// flexWrap: "nowrap",
		// // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		// transform: "translateZ(0)",
		// overflow: "unset"
	},
	button: {
		width: "100%",
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	gridListsmall: {
		// width: "100%"
		overflow: "hidden"
	}
});

class OfferBlock extends React.Component {
	state = {
		openSnack: false,
		storeCurrency: "$",
		hrefUrl: "https://app.enteroffer.com/?s=",
		editUrl: "",
		open: false
	};

	onToggleItemStatus = itemID => {
		const { items } = this.state;
		var tempArray = items;
		const inxOfItem = items.indexOf(itemID);

		if (inxOfItem >= 0) {
			tempArray.splice(inxOfItem, 1);
		} else {
			tempArray.push(itemID);
		}
		this.setState({ items: tempArray });
	};

	componentDidMount() {
		if (liveTesting) {
			this.setState({ hrefUrl: "https://test.enteroffer.com/?s=" });
		}

		let offerData = this.props.data;
		var url = "";
		for (let item in offerData.products) {
			url +=
				"&p=" +
				offerData.products[item].productID +
				"&v=" +
				offerData.products[item].variantID;
		}
		this.setState({ editUrl: url });
	}

	// USED TO GENERATE PARAMS FOR CF
	parameterizeArray(key, arr) {
		arr = arr.map(encodeURIComponent);
		return key + "[]=" + arr.join("&" + key + "[]=");
	}

	// Reset all checkout state variables for store
	handleCloseCancel = () => {
		this.setState({
			checkoutDialogOpen: false,
			failedToCreateOrder: false,
			checkoutLoading: false,
			isButtonDisabled: false,
			showCantCheckoutMessage: false,
			showItemsChanged: false,
			changedStoresExpired: false,
			checkoutButtonLoading: false
		});
	};

	handleCloseSnack = () => {
		this.setState({ openSnack: false });
	};

	extractUrl(item) {
		var tesst = item.productUrl;
		var test = tesst.match(/[^/]*\/[^/]*\/[^/]*/);
		var str = test[0];
		str = str.replace("https://", "");
		str = str.replace("http://", "");
		str = str.replace("www.", "");
		return str;
	}

	cartAbandonedUrl(data) {
		if (data.cartAbandoned === true) {
			return "&carta=true";
		} else {
			return "";
		}
	}
	handleClickOpen = () => {
		this.setState({ open: true });
	};
	handleClose = () => {
		this.setState({ open: false });
	};
	render() {
		const { classes, data, type, isMobile } = this.props;
		const { hrefUrl, editUrl } = this.state;

		var fullEditUrl = "";
		var spp = "";
		if (data.spp !== undefined && data.spp !== null) {
			spp = "&spp=" + data.spp;
		}
		if (data.products) {
			fullEditUrl =
				hrefUrl +
				data.storeName +
				"&url=" +
				this.extractUrl(data.products[0]) +
				editUrl +
				this.cartAbandonedUrl(data) +
				spp;
		}
		var edit = false;
		if (type === 0) edit = true;

		return (
			<Grid
				className={classes.gridList}
				container
				style={{
					textAlign: "left",
					fontSize: "1em",
					position: "relative",
					width: "350px",
					height: "100%"
				}}
			>
				<Grid item xs={12} style={{ display: " inline-flex" }}>
					<Grid
						item
						xs={7}
						style={{
							color: data.storeName ? null : "transparent",
							padding: "0.5em",
							display: "inline-table"
						}}
					>
						<span
							style={{
								verticalAlign: "-webkit-baseline-middle"
							}}
						>
							{data.storeName ? _.startCase(data.storeName) : "Default"}
						</span>
					</Grid>
					<Grid
						item
						xs={5}
						style={{
							width: isMobile ? "160px" : "200px"
						}}
					>
						<IconButton disabled={!edit}>
							<a style={{ textDecoration: "none" }} href={fullEditUrl}>
								<Edit
									className={classes.icon}
									style={{
										color: edit ? "#35B2FF" : "transparent"
									}}
								/>
							</a>
						</IconButton>
						<IconButton onClick={this.handleClickOpen} style={{ float: "right" }}>
							<Expand
								className={classes.icon}
								style={{
									color: "#35B2FF"
								}}
							/>
						</IconButton>
					</Grid>
				</Grid>
				<Grid item xs={7}>
					<SmallGrid editUrl={fullEditUrl} type={type} data={data} isMobile={isMobile} />
				</Grid>
				<Grid
					item
					xs={5}
					style={{
						padding: "1em",
						width: isMobile ? "160px" : "200px",
						height: "100%",
						textAlign: "center"
					}}
				>
					<Grid item container>
						<p
							style={{
								display: "table-cell",
								verticalAlign: "middle",
								width: "100%",
								color: "#929292"
							}}
						>
							Submitted
							<br />
							<span
								style={{
									fontWeight: 500,
									color: "#000"
								}}
							>
								{moment(data.submittedDate).format("MMM DD, YYYY")}
							</span>
						</p>
						<p
							style={{
								display: "table-cell",
								verticalAlign: "middle",
								width: "100%",
								color: "#929292"
							}}
						>
							Order Value
							<br />
							<span
								style={{
									fontWeight: 500,
									color: "#000"
								}}
							>
								{data.currencySign}
								{data.listPrice}
							</span>
						</p>
						<p
							style={{
								display: "table-cell",
								verticalAlign: "middle",
								width: "100%",
								color: "#929292"
							}}
						>
							You Offered
							<br />
							<span
								style={{
									fontWeight: 500,
									color:
										data.boost !== null && data.boost !== undefined
											? "#35b2ff"
											: "#000"
								}}
							>
								{data.currencySign}
								{data.bidPrice}
								{data.boost !== null && data.boost !== undefined ? (
									<img
										src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/RocketBoost.png"
										height={"16px"}
									/>
								) : null}
							</span>
						</p>
					</Grid>
				</Grid>
				<div>
					<Dialog open={this.state.open} onClose={this.handleClose}>
						<DialogContent>
							<Receipt offer={data} />
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary" autoFocus>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</Grid>
		);
	}
}

OfferBlock.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width, height }) => ({
	isMobile: width < 600,
	curWidth: width > 600 ? 24 : 8,
	width: width,
	height: height
});

export default withSizes(mapSizesToProps)(withStyles(styles)(OfferBlock));
