import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import _ from "lodash";
// import tileData from "./tileData";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "hidden",
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		width: 200,
		// height: 260,
		overflowY: "hidden"
	},
	icon: {
		color: "#35B2FF"
	}
}));

export default function TitlebarGridList(props) {
	const classes = useStyles();

	const tileData = props.data.products;

	let len = false;
	if (tileData && tileData.length < 2) len = true;

	let edit = false;
	if (props.type === 0) edit = true;

	return (
		<div className={classes.root}>
			<GridList cellHeight={120} className={classes.gridList} spacing={2}>
				{tileData &&
					tileData.slice(0, 4).map(tile => (
						<GridListTile
							style={{ borderRadius: "10px" }}
							key={tile.image}
							cols={len ? 2 : 1}
							rows={len ? 2 : 1}
						>
							<img
								src={tile.image}
								style={{ objectFit: "contain" }}
								alt={"productImg"}
							/>
						</GridListTile>
					))}
				{tileData && tileData.length === 3 ? (
					<GridListTile key={Math.random()} style={{ color: "transparent" }}>
						-
					</GridListTile>
				) : tileData && tileData.length === 2 ? (
					<span>
						<GridListTile key={Math.random()} style={{ color: "transparent" }}>
							-
						</GridListTile>
						<GridListTile key={Math.random()} style={{ color: "transparent" }}>
							-
						</GridListTile>
					</span>
				) : null}
			</GridList>
		</div>
	);
}
