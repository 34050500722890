import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { Elements, StripeProvider } from "react-stripe-elements";
import { CheckoutForm } from "./index";
import { fb, authFunctions, db } from "../firebase";
import { injectStripe } from "react-stripe-elements";
import _ from "lodash";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "100%",
		fontFamily: "Nunito Sans, sans-serif "
	},
	cirProgress: {
		display: "inline-block"
	},
	dialog: {
		textAlign: "center",
		padding: "2em",
		fontFamily: "Nunito Sans, sans-serif ",
		overflow: "none"
	}
});

class NewSettingsModal extends Component {
	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
	}

	async submit(ev) {
		if (!this.state.loading) {
			this.setState({
				loading: true
			});
		}
		let userData = await db.getUserMeta();
		let username = userData.firstName + " " + userData.lastName;
		let { token } = await this.props.stripe.createToken({
			name: username,
			type: "card"
		});
		if (token) {
			if (token.error) {
				//return error
				let errorStr =
					"Please fill in correct card details, or try again later.";
				this.setState({
					error: errorStr,
					snackBarOpen: true,
					loading: false
				});
			} else {
				let res = await fb.createStripeCustomer(token.id);
				console.log(res);
				if (res.data === true) {
					let str = "Successfully updated card details.";
					this.setState({
						error: str,
						snackBarOpen: true,
						loading: false
					});
					this.props.handleClose();
				} else {
					let errorStr =
						"Please fill in correct card details, or try again later.";
					this.setState({
						error: errorStr,
						snackBarOpen: true,
						loading: false
					});
				}
			}
		} else {
			let errorStr =
				"Please fill in correct card details, or try again later.";
			this.setState({
				error: errorStr,
				snackBarOpen: true,
				loading: false
			});
		}
	}

	state = {
		confirmPopup: false,
		company: "",
		line1: "",
		line2: "",
		city: "",
		address_zip: "",
		state: "",
		state_code: 0,
		country: "",
		country_code: 0,
		countries: [],
		states: [],

		number: "",
		cvc: "",
		exp_month: "",
		exp_year: "",
		error: "",

		snackBarOpen: false,
		vertical: "bottom",
		horizontal: "left",

		loading: false
	};

	// componentWillUnmount() {
	//   clearTimeout(this.timer);
	// }

	componentDidMount() {
		var promises = [];
		promises[0] = db
			.getUserMeta()
			.then(snapshot => {
				var company = "";
				var line1 = "";
				var line2 = "";
				var city = "";
				var address_zip = "";
				var state = "";
				var state_code = 0;
				var country = "";
				var country_code = 0;
				if (snapshot.billingAddress) {
					company = snapshot.billingAddress.company;
					if (
						typeof snapshot.billingAddress.company ===
							"undefined" ||
						snapshot.billingAddress.company === null
					) {
						company = "";
					}
					line1 = snapshot.billingAddress.line1;
					line2 = snapshot.billingAddress.line2;
					if (
						typeof snapshot.billingAddress.line2 === "undefined" ||
						snapshot.billingAddress.line2 === null
					) {
						line2 = "";
					}
					city = snapshot.billingAddress.city;
					address_zip = snapshot.billingAddress.address_zip;
					state = snapshot.billingAddress.state;
					state_code = snapshot.billingAddress.state_code;
					if (
						typeof snapshot.billingAddress.state_code ===
							"undefined" ||
						snapshot.billingAddress.state_code === null
					) {
						state_code = "";
					}
					country = snapshot.billingAddress.country;
					country_code = snapshot.billingAddress.country_code;
					if (
						typeof snapshot.billingAddress.country_code ===
							"undefined" ||
						snapshot.billingAddress.country_code === null
					) {
						country_code = "";
					}
				}
				this.setState({
					company: company,
					line1: line1,
					line2: line2,
					city: city,
					address_zip: address_zip,
					state: state,
					state_code: state_code,
					country: country,
					country_code: country_code
				});
				return snapshot;
			})
			.catch(e => console.log("error", e));
		promises[1] = fetch(
			`https://www.geonames.org/childrenJSON?geonameId=6295630&style=long`
		).then(data => {
			return data.json();
		});
		Promise.all(promises).then(res => {
			this.initializeCountries(res[0], res[1]);
		});
	}

	initializeCountries = async function(userMeta, jData) {
		var counts = jData.geonames.length;
		for (var i = 0; i < counts; i++) {
			var continentID = jData.geonames[i].geonameId;
			let continentData = await fetch(
				`https://www.geonames.org/childrenJSON?geonameId=${continentID}&style=long`
			);
			let cJson = await continentData.json();
			var stateCounts =
				cJson.geonames.length < cJson.totalResultsCount
					? cJson.geonames.length
					: cJson.totalResultsCount;
			for (let j = 0; j < stateCounts; j++) {
				var cName = cJson.geonames[j].name;
				if (
					cName === "Australia" ||
					cName === "France" ||
					cName === "New Zealand" ||
					cName === "Canada" ||
					cName === "United States" ||
					cName === "United Kingdom" ||
					cName === "Switzerland"
				) {
					const newItem = {
						id: cJson.geonames[j].geonameId,
						val: cJson.geonames[j].name,
						code: cJson.geonames[j].countryCode
					};
					const newCountries = this.state.countries.concat(newItem);
					this.setState(
						{
							countries: newCountries
						},
						() => {
							if (_.has(userMeta, "userMeta.billingAddress")) {
								if (
									cJson.geonames[j].countryCode ===
										userMeta.billingAddress.country_code ||
									cJson.geonames[j].name ===
										userMeta.billingAddress.country
								) {
									this.initializeStates(
										cJson.geonames[j].geonameId
									);
									this.setState({
										country_code:
											cJson.geonames[j].countryCode
									});
								}
							}
						}
					);
				}
			}
		}
		var sortedCountries = this.state.countries.concat().sort((a, b) => {
			if (a.val < b.val) return -1;
			if (a.val > b.val) return 1;
			return 0;
		});
		this.setState({ countries: sortedCountries });
	};

	findItemsWith = function(items, value, prop) {
		for (let i in items) {
			if (items[i][prop] === value) {
				return items[i];
			}
		}
		return false;
	};

	initializeStates = geoId => {
		var newStates = [];
		fetch(
			`https://www.geonames.org/childrenJSON?geonameId=${geoId}&style=long`
		)
			.then(stateData => {
				return stateData.json();
			})
			.then(sJson => {
				let counts =
					sJson.geonames.length < sJson.totalResultsCount
						? sJson.geonames.length
						: sJson.totalResultsCount;
				for (var i = 0; i < counts; i++) {
					var newState = {
						id: sJson.geonames[i].geonameId,
						val: sJson.geonames[i].name,
						iso2code: sJson.geonames[i].adminCodes1.ISO3166_2
					};
					newStates.push(newState);
				}
				this.setState({ states: newStates });
				return;
			});
	};

	handleChange = name => event => {
		if (name === "country") {
			let countryEntry = this.findItemsWith(
				this.state.countries,
				event.target.value,
				"val"
			);
			this.setState({ country_code: countryEntry.code });
			let geoId = countryEntry.id;
			var newStates = [];
			fetch(
				`https://www.geonames.org/childrenJSON?geonameId=${geoId}&style=long`
			)
				.then(stateData => {
					return stateData.json();
				})
				.then(sJson => {
					let counts =
						sJson.geonames.length < sJson.totalResultsCount
							? sJson.geonames.length
							: sJson.totalResultsCount;
					for (var i = 0; i < counts; i++) {
						var newState = {
							id: sJson.geonames[i].geonameId,
							val: sJson.geonames[i].name,
							iso2code: sJson.geonames[i].adminCodes1.ISO3166_2
						};
						newStates.push(newState);
					}
					this.setState({ states: newStates });
					return;
				});
		}
		if (name === "state") {
			let stateEntry = this.findItemsWith(
				this.state.states,
				event.target.value,
				"val"
			);
			this.setState({ state_code: stateEntry.iso2code });
		}
		this.setState({ [name]: event.target.value });
	};

	validateAllFields = () => {
		var fieldsToCheck = [
			this.state.line1,
			this.state.city,
			this.state.address_zip,
			this.state.state,
			this.state.country
		];
		var errorNames = [
			"address line 1",
			"city",
			"post code",
			"state",
			"country"
		];
		var errorToPop = [];
		let hasError = false;
		for (let i in fieldsToCheck) {
			if (fieldsToCheck[i] === "") {
				errorToPop.push(errorNames[i]);
				hasError = true;
			}
		}
		if (hasError) {
			return errorToPop;
		} else {
			return false;
		}
	};

	saveChanges = () => {
		let error = this.validateAllFields();
		if (error === false) {
			fetch(
				`https://restcountries.eu/rest/v2/alpha/${this.state.country_code}`
			)
				.then(data => {
					return data.json();
				})
				.then(res => {
					db.updateBillingAddress(
						this.state.address_zip,
						this.state.company,
						this.state.city,
						this.state.country,
						this.state.line1,
						this.state.line2,
						this.state.state,
						this.state.state_code,
						this.state.country_code
					);
					db.updateCurrAndSymb(
						res.currencies[0].code,
						res.currencies[0].symbol
					);
					console.log("Saved changes");
					this.props.handleClose();
				});
		} else {
			var errorStr = "Please fill in";
			for (let x in error) {
				errorStr += " " + error[x] + ",";
			}
			errorStr = errorStr.slice(0, errorStr.length - 1);
			errorStr += ".";
			this.setState({ error: errorStr, snackBarOpen: true });
		}
	};

	validatePaymentFields = () => {
		var fieldsToCheck = [
			this.state.number,
			this.state.cvc,
			this.state.exp_month,
			this.state.exp_year
		];
		var errorNames = ["card number", "cvc", "expiry month", "expiry year"];
		var errorToPop = [];
		let hasError = false;
		for (let i in fieldsToCheck) {
			if (fieldsToCheck[i] === "") {
				errorToPop.push(errorNames[i]);
				hasError = true;
			}
		}
		if (hasError) {
			return errorToPop;
		} else {
			return false;
		}
	};

	handleCloseCancel = () => {
		this.props.handleClose();
	};

	deleteAccount = () => {
		authFunctions
			.deleteUser(this.state.email, this.state.password)
			.then(res => {
				console.log(res);
			})
			.catch(e => {
				let errorStr = "Incorrect credentials, please try again.";
				this.setState({ error: errorStr, snackBarOpen: true });
				console.error(e);
			});
	};

	handleClose = () => {
		this.setState({ snackBarOpen: false });
	};

	render() {
		const { classes } = this.props;
		const {
			vertical,
			horizontal,
			snackBarOpen,
			error,
			loading
		} = this.state;
		return (
			<div>
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={snackBarOpen}
					onClose={this.handleClose}
					message={<span id="message-id">{error}</span>}
				/>
				{this.props.openBilling ? (
					<Dialog
						fullScreen={this.props.isMobile}
						open={this.props.openBilling}
						onClose={this.props.handleClose}
						className={classes.dialog}
						style={{
							fontFamily: "Nunito Sans, sans-serif"
						}}
					>
						<DialogTitle id="alert-dialog-title">
							{"Update Billing Details"}
						</DialogTitle>
						<DialogContent className={classes.dialog}>
							<TextField
								id="standard-name"
								label="Company"
								className={classes.textField}
								value={this.state.company}
								onChange={this.handleChange("company")}
								margin="normal"
							/>
							<TextField
								id="standard-name"
								label="Address Line 1*"
								autoComplete="shipping address-line1"
								className={classes.textField}
								value={this.state.line1}
								onChange={this.handleChange("line1")}
								margin="normal"
							/>
							<TextField
								id="standard-name"
								label="Address Line 2"
								autoComplete="shipping address-line2"
								className={classes.textField}
								value={this.state.line2}
								onChange={this.handleChange("line2")}
								margin="normal"
							/>
							<TextField
								id="standard-name"
								label="City*"
								autoComplete="shipping locality"
								className={classes.textField}
								value={this.state.city}
								onChange={this.handleChange("city")}
								margin="normal"
							/>
							<TextField
								id="standard-name"
								label="Post Code*"
								autoComplete="shipping postal-code"
								className={classes.textField}
								value={this.state.address_zip}
								onChange={this.handleChange("address_zip")}
								margin="normal"
							/>
							<TextField
								id="standard-select-state"
								select
								label="Select State*"
								autoComplete="shipping region"
								className={classes.textField}
								value={this.state.state}
								onChange={this.handleChange("state")}
								SelectProps={{
									MenuProps: {
										className: classes.menu
									}
								}}
								margin="normal"
							>
								{this.state.states.map(option => (
									<MenuItem
										key={option.id}
										value={option.val}
									>
										{option.val}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id="standard-select-country"
								select
								label="Select Country*"
								autoComplete="shipping region"
								className={classes.textField}
								value={this.state.country}
								onChange={this.handleChange("country")}
								SelectProps={{
									MenuProps: {
										className: classes.menu
									}
								}}
								margin="normal"
							>
								{this.state.countries.map(option => (
									<MenuItem
										key={option.id}
										value={option.val}
									>
										{option.val}
									</MenuItem>
								))}
							</TextField>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.handleCloseCancel}
								color="primary"
							>
								Cancel
							</Button>
							<Button onClick={this.saveChanges} color="primary">
								Save
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.openPayment ? (
					<Dialog
						fullScreen={this.props.isMobile}
						open={this.props.openPayment}
						onClose={this.props.handleClose}
					>
						<DialogContent
							className={classes.dialog}
							style={{ width: !this.props.isMobile ? 400 : null }}
						>
							<h2>
								<span
									style={{
										color: "#35B2FF",
										fontWeight: 900
									}}
								>
									UPDATE
								</span>
								<span style={{ fontWeight: 900 }}>
									{" "}
									PAYMENT
								</span>
							</h2>
							{loading && (
								<Lottie
									height={200}
									width={200}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice"
										}
									}}
								/>
							)}
							<div style={{ padding: "0em 1em" }}>
								<StripeProvider apiKey="pk_live_maA6v1kcLz9op0OzwcwpCtCQ">
									<Elements>
										<CheckoutForm
											handleResult={this.props.submit}
										/>
									</Elements>
								</StripeProvider>
							</div>
						</DialogContent>
						<DialogActions
							style={{
								marginBottom: this.props.isMobile
									? "50vh"
									: null
							}}
						>
							<a
								href="https://www.stripe.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									style={{ height: "1.5em" }}
									alt="Powered by Stripe"
									src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/poweredByStripe.png"
								/>
							</a>
							<Button
								onClick={this.handleCloseCancel}
								color="primary"
							>
								Cancel
							</Button>
							<Button onClick={this.submit} color="primary">
								Save
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.openDelete ? (
					<Dialog
						fullScreen={this.props.isMobile}
						open={this.props.openDelete}
						onClose={this.props.handleClose}
					>
						<DialogContent
							className={classes.dialog}
							style={{ width: !this.props.isMobile ? 400 : null }}
						>
							Please enter your email and password to confirm
							deletion
							<TextField
								id="delete-email"
								label="Email"
								autoComplete="off"
								value={this.state.email}
								onChange={ev => {
									this.setState({ email: ev.target.value });
								}}
								className={classes.textField}
								margin="normal"
								type="email"
							></TextField>
							<TextField
								id="delete-password"
								label="Password"
								autoComplete="off"
								value={this.state.password}
								onChange={ev => {
									this.setState({
										password: ev.target.value
									});
								}}
								className={classes.textField}
								margin="normal"
								type="password"
							></TextField>
						</DialogContent>
						<DialogActions
							style={{
								marginBottom: this.props.isMobile
									? "50vh"
									: null
							}}
						>
							<Button
								onClick={this.deleteAccount}
								color="primary"
							>
								Confirm
							</Button>
							<Button
								onClick={this.handleCloseCancel}
								color="primary"
							>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
				) : null}
			</div>
		);
	}
}

export default injectStripe(withStyles(styles)(NewSettingsModal));
