import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";

const tutorialSteps = [
	{
		label: "Introduction Step 1",
		line1: "Shop on participatin stores",
		line2:
			"Browse stores from your EnterOffer dashboard or the store directory on our website.",
		imgPath:
			"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/introStep1.jpeg"
	},
	{
		label: "Introduction Step 2",
		line1: "Locate the EnterOffer symbol then add product to cart",
		line2:
			"You can find it on the product pages across all participating online stores.",
		imgPath:
			"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/introStep2.jpeg"
	},
	{
		label: "Introduction Step 3",
		line1: "Go to your cart and click offer",
		line2:
			"An EnterOffer window will appear and you can sign in to submit your offer price.",
		imgPath:
			"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/introStep3.jpeg"
	},
	{
		label: "Introduction Step 4",
		line1: "Make an offer on your cart and submit!",
		line2:
			"Name your price on all products in your cart. After you submit, the retailer will accept or reject your offer within 24 hours.",
		imgPath:
			"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/introStep4.jpeg"
	},
	{
		label: "Introduction Step 5",
		line1: "View and manage your offers",
		line2:
			"From your dashboard you can view or amend your pending offers, view your past offers and manage your EnterOffer account.",
		imgPath:
			"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/introStep5.jpeg"
	}
];

const styles = theme => ({
	root: {
		maxWidth: 540,
		height: "93.5vh",
		flexGrow: 1,
		marginLeft: "auto",
		marginRight: "auto"
	},
	img: {
		maxHeight: "100%",
		maxWidth: "100%",
		overflow: "hidden",
		display: "block",
		margin: "auto"
	},
	mobileStepper: {
		background: "#fff"
	}
});

class SwipeableTextMobileStepper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0
		};
		this.handleLast = this.handleLast.bind(this);
	}

	handleNext = () => {
		this.setState(prevState => ({
			activeStep: prevState.activeStep + 1
		}));
	};

	handleBack = () => {
		this.setState(prevState => ({
			activeStep: prevState.activeStep - 1
		}));
	};

	handleStepChange = activeStep => {
		this.setState({ activeStep });
		if (activeStep === 4) {
			this.props.onSelectLast(true);
		}
	};

	handleLast = () => {
		var flag = true;
		this.props.onSelectLast(flag);
	};

	render() {
		const { classes, theme } = this.props;
		const { activeStep } = this.state;
		const maxSteps = tutorialSteps.length;

		return (
			<div className={classes.root}>
				<SwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={this.handleStepChange}
					enableMouseEvents
				>
					{tutorialSteps.map((step, index) => (
						<div style={{ textAlign: "center", height: "85vh" }}>
							<img
								className={classes.img}
								src={tutorialSteps[activeStep].imgPath}
								alt={tutorialSteps[activeStep].label}
								style={{
									height: "100% !important",
									maxHeight: "100% !important"
								}}
							/>
							{/* <b>
								<p style={{ marginTop: "-20px" }}>
									{tutorialSteps[activeStep].line1}
								</p>
							</b>
							<p style={{ color: "#7f7f7f", height: "2em" }}>
								{tutorialSteps[activeStep].line2}
							</p> */}
						</div>
					))}
				</SwipeableViews>
				<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					className={classes.mobileStepper}
					nextButton={
						<Button
							size="small"
							onClick={
								activeStep === maxSteps - 1
									? this.handleLast
									: this.handleNext
							}
						>
							Next
							{theme.direction === "rtl" ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							size="small"
							onClick={this.handleBack}
							disabled={activeStep === 0}
						>
							{theme.direction === "rtl" ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
							Back
						</Button>
					}
				/>
			</div>
		);
	}
}

SwipeableTextMobileStepper.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
	SwipeableTextMobileStepper
);
