import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import withSizes from "react-sizes";
import { OfferBlock } from "../components";
import GridList from "@material-ui/core/GridList";
var liveTesting = false;

const styles = theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "auto",
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		flexWrap: "nowrap",
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: "translateZ(0)"
	},
	title: {
		color: theme.palette.primary.light
	},
	topSection: {
		paddingBottom: theme.spacing(1)
	},

	nameAndCheckout: {
		flexGrow: 1,
		overflow: "hidden",
		textAlign: "left"
	},
	media: {
		flexGrow: 1,
		width: "auto",
		height: "60px"
	},
	mediaMobile: {
		flexGrow: 1,
		width: "auto",
		height: "30px"
	},
	text: {
		color: "black",
		fontFamily: "Nunito Sans, sans-serif",
		letterSpacing: "1px"
	}
});

class Store extends React.Component {
	state = {
		hrefUrl: "https://enteroffer.com/campaign/?store="
	};

	static propTypes = {
		userName: PropTypes.string,
		userID: PropTypes.string,
		type: PropTypes.string.isRequired,

		storeDetails: PropTypes.shape({
			name: PropTypes.string.isRequired,
			storeNotCamel: PropTypes.string.isRequired,
			logo: PropTypes.string.isRequired,
			storeItems: PropTypes.array
		}).isRequired
	};

	onToggleItemStatus = itemID => {
		const { items } = this.state;
		var tempArray = items;
		const inxOfItem = items.indexOf(itemID);

		if (inxOfItem >= 0) {
			tempArray.splice(inxOfItem, 1);
		} else {
			tempArray.push(itemID);
		}
		this.setState({ items: tempArray });
	};

	// BUILDS MOBILE OR DESKTOP ITEMS IN DOM
	buildResults = () => {
		const { storeDetails } = this.props;
		// const storeItems = storeDetails.storeItems;
		const storeItems = storeDetails;
		// if storeitems is null or empty
		if (_.size(storeItems) === 0) {
			return {};
		}
		return storeItems;
	};

	componentDidMount() {
		const { storeDetails } = this.props;
		// const storeItems = storeDetails.storeItems;
		const storeItems = storeDetails;
		let tempList = [];
		for (let i in storeItems) {
			tempList.push(Number(i));
		}
		this.setState({ items: tempList });
		if (liveTesting) {
			this.setState({
				hrefUrl: "https://oaklas-website-dev.web.app/campaign/?store="
			});
		}
	}

	handleCloseSnack = () => {
		this.setState({ openSnack: false });
	};

	extractUrl(item) {
		var tesst = item.productUrl;
		var test = tesst.match(/[^/]*\/[^/]*\/[^/]*/);
		return test[0];
	}

	render() {
		const { classes, type, curWidth, isMobile } = this.props;

		const results = this.buildResults();

		return (
			<div>
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						height: isMobile ? "320px" : "380px",
						borderRadius: "0px",
						backgroundColor: "transparent",
						width: "98vw",
						padding: isMobile ? "0px" : null,
						position: "relative",
						left: "50%",
						right: "50%",
						marginLeft: "-50vw",
						marginRight: "-50vw",
						overflow: "hidden"
					}}>
					<Grid
						className={classes.gridList}
						container
						spacing={0}
						direction='row'>
						{_.size(results) > 0 ? (
							<GridList
								className={classes.gridList}
								spacing={curWidth}
								direction='row'
								style={{ margn: "0" }}>
								{results &&
									_.map(results, (orders, i) => (
										<Paper
											key={i}
											className={classes.root}
											elevation={1}
											style={{
												height: "320px",
												borderRadius: "10px",
												overflow: "unset",
												marginTop: "1em",
												marginLeft: isMobile
													? i === _.size(results) - 1
														? "1.5em"
														: "1em"
													: i === _.size(results) - 1
													? "5em"
													: "1em",
												marginRight: "0.5em",
												width: "auto",
												transition: "width 2s",
												zoom: isMobile ? "0.8" : null
											}}>
											<OfferBlock
												data={orders}
												type={type}
											/>
										</Paper>
									)).reverse()}
							</GridList>
						) : null}
					</Grid>
				</Paper>
			</div>
		);
	}
}

Store.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width, height }) => ({
	isMobile: width < 600,
	curWidth: width > 600 ? 24 : 8,
	width: width,
	height: height
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Store));
