import React, { Component } from "react";
import "./App.css";
import { Home, PageNotFound, PageContainer, Settings, NewLogin, NewSignup } from "./pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "typeface-nunito";
import AuthorizedRoute from "./authorizedRoute";
import { StripeProvider } from "react-stripe-elements";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#484848",
			main: "#35B2FF",
			// dark: "#000000",
			contrastText: "#fff"
		},
		fontFamily: "Nunito Sans, sans-serif"
	},

	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: ["Nunito Sans", "sans-serif"].join(",")
	},
	overrides: {
		MuiDrawer: {
			// Name of the styleSheet
			paper: {
				// Name of the rule
				backgroundColor: "black"
			}
		},
		MuiBottomNavigationAction: {
			label: {
				color: "white"
			}
		}
	}
});
// Stripe.setPublishableKey('pk_test_IkDcaaN5X30w1eeBIxZcQCY4');
//Stripe.setPublishableKey('pk_live_maA6v1kcLz9op0OzwcwpCtCQ');
class App extends Component {
	render() {
		return (
			// <StripeProvider apiKey="pk_live_maA6v1kcLz9op0OzwcwpCtCQ"> // live
			// testing
			<StripeProvider apiKey="pk_test_IkDcaaN5X30w1eeBIxZcQCY4">
				<MuiThemeProvider theme={theme}>
					<Router>
						<div
							style={{
								fontFamily: "Nunito Sans, sans-serif",
								display: "flex",
								flexDirection: "column",
								minHeight: "100vh",
								maxWidth: "100%",
								overflowX: "hidden"
							}}
						>
							<Switch>
								<Route exact path="/signin" component={NewLogin} />
								<AuthorizedRoute exact path="/" component={HomeWrapped} />
								<AuthorizedRoute
									exact
									path="/settings"
									component={SettingsWrapped}
								/>
								<Route exact path="/signup" component={NewSignup} />
								<Route component={PageNotFound} />
							</Switch>
						</div>
					</Router>
				</MuiThemeProvider>
			</StripeProvider>
		);
	}
}
// const HomeWrapped = ({ location, history }) => <PageContainer page={<Home searchParams={{...location.state }}/>} />
// stateContext={context.state}/>
const HomeWrapped = () => <PageContainer page={Home} />;
const SettingsWrapped = () => <PageContainer page={Settings} />;

export default App;
