import React, { Component } from "react";
import { Store } from "../components";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withSizes from "react-sizes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { db } from "../firebase";
import _ from "lodash";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { HelpModal } from "../components";
import Snackbar from "@material-ui/core/Snackbar";
import Update from "@material-ui/icons/Refresh";
import TextField from "@material-ui/core/TextField";
import "../App.css";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
	root: {
		flexGrow: 1,
		fontFamily: "Nunito Sans, sans-serif",
		paddingTop: theme.spacing(2)
	},
	storeRoot: {
		// paddingTop: theme.spacing(2),
	},
	bidInfo: {
		display: "inline-block",
		fontFamily: "Nunito Sans, sans-serif",
		paddingRight: theme.spacing(2)
	},
	bidInfoMobile: {
		display: "inline-block",
		fontFamily: "Nunito Sans, sans-serif",
		paddingRight: theme.spacing(2),
		textAlign: "center"
	},
	button: {
		// margin: theme.spacing(1),
		background: "#35B2FF",
		color: "#FFF",
		fontWeight: 700,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#35B2FFCC"
		}
	},
	buttonUpdate: {
		color: "#35B2FF"
	},
	nameButton: {
		textAlign: "left",
		margin: "0",
		padding: "0",
		"&:hover": {
			background: "transparent"
		}
	},
	nameButtonMobile: {
		textAlign: "left",
		margin: "0",
		padding: "0"
	},
	text: {
		fontSize: "12px",
		fontWeight: 400,
		letterSpacing: "1px",
		color: "#999",
		fontFamily: "Nunito Sans, sans-serif",
		textTransform: "lowercase"
	},
	progress: {
		color: "#35B2FF",
		textAlign: "center",
		margin: theme.spacing(2)
	},
	// itemGrid: {
	//     flexGrow: 1,
	//     maxWidth: 1200,
	//     textAlign: 'center',
	//     marginLeft: 'auto',
	//     marginRight: 'auto',
	//     display: 'block',
	//     marginBottom: '0px',
	//     paddingBottom: '0px'
	// },
	logo: {
		textAlign: "center"
	},
	media: {
		width: "350px",
		height: "280px"
	},
	divMakeOffer: {
		backgroundColor: "#f6f6f8",
		width: "60%",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "1em",
		padding: "1em"
	},
	titleText: {
		paddingTop: "50px",
		padding: "0px 16px"
	},
	textParagraph: {
		fontFamily: "Nunito Sans, sans-serif",
		color: "#9e9e9e"
	},
	userName: {
		textTransform: "capitalize",
		fontWeight: 700,
		fontSize: "1.5em"
	},
	row: {
		display: "flex",
		justifyContent: "center"
	},
	newOffer: {
		display: "inline-flex",
		verticalAlign: "middle"
	},
	imageSrc: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: "cover",
		backgroundPosition: "center 40%"
	},
	liveStores: {
		fontSize: "1em",
		textTransform: "uppercase",
		fontWeight: 700,
		color: "#000"
	},
	liveStoresDiv: {
		position: "relative",
		width: "100%",
		color: "#fff",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		borderRadius: "10px",
		boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)"
	},
	tabsStyle: {
		display: "inline-flex",
		borderRadius: "30px",
		backgroundColor: "#35B2FF44",
		padding: "4px",
		minHeight: "30px !important"
	},
	tabStyle: {
		padding: 0,
		fontWeight: 700,
		borderRadius: "50px",
		textTransform: "capitalize",
		fontSize: "0.9em",
		minWidth: "100px !important",
		minHeight: "30px !important"
	}
});

class Home extends Component {
	state = {
		data: [],
		type: 0,
		userName: "",
		stores: [],
		userID: "",
		curPending: 0,
		curApproved: 0,
		curRejected: 0,
		loading: true,
		metaLoading: true,
		noItemsInAnyStore: false,
		openName: false,
		isNewUser: false,
		openSuccess: false,
		upAndDownVal: 0,
		checkForUpdatesClicked: false,
		openLearnMoreUser: false,
		openRequest: false,
		suggestion: "",
		suggestedRetailer: "",
		totalSaved: 0,
		spp: {}
	};

	componentDidMount() {
		db.getSPP().then(snapshot => {
			this.setState({ spp: snapshot });
		});

		db.getCurrentUser().then(user => {
			this.setState({ userID: user.uid });
			this.checkForUpdates(true);
		});
	}

	// Used to call CF to trigger updates in user DB
	// didLogin Boolean to show Snackbar only if update button is clicked
	checkForUpdates = didLogin => {
		db.getUserMeta()
			.then(snapshot => {
				if (didLogin) {
					const firstName = snapshot.firstName;
					const lastName = snapshot.lastName;
					const isNewUser = snapshot.isNewUser;
					const name = firstName + " " + lastName;

					if (isNewUser) {
						this.setState({ isNewUser: true });
						db.setNewUserToFalse();
					}

					this.setState({ userName: name });
				}
				this.setState({ metaLoading: false });
			})
			.catch(e => console.log("error", e));

		db.getOfferTypeCount()
			.then(snapshot => {
				this.setState({
					curApproved: snapshot.approvedCount,
					curPending: snapshot.pendingCount,
					curRejected: snapshot.rejectedCount
				});
			})
			.catch(e => {
				// Dummy data in catch
				this.setState({ curApproved: 0, curPending: 0, curRejected: 0 });
				console.log("error", e);
			});

		// Disable check for updates button
		this.setState({ checkForUpdatesClicked: true });
		if (!didLogin) {
			this.setState({ loading: true });
		}

		var uid = db.getUserID();
		let cfCheckShopify = `https://us-central1-oaklas-user.cloudfunctions.net/checkForFailedShopifyCall?uid=${uid}`;

		fetch(cfCheckShopify)
			.then(resp => resp.json())
			.then(resp => {
				this.setType(this.state.type);
				if (!didLogin) {
					this.setState({ openSuccess: true });
				}
				//get total saved
				db.getItems("approved")
					.then(snapshot => {
						var saved = this.snapshotToArray(snapshot);
						this.setState({ totalSaved: saved.toFixed(2) });
					})
					.catch(e => console.log("error", e));

				this.setState({ checkForUpdatesClicked: false });
				return;
			})
			.catch(e => {
				this.setState({ checkForUpdatesClicked: false });
				console.log(e);
			});
	};

	// Displays type
	setType(type) {
		if (type === 0) {
			this.getItemsPerStore("pending");
		} else if (type === 1) {
			this.getItemsPerStore("approved");
		} else if (type === 2) {
			this.getItemsPerStore("rejected");
		}
	}

	getItemsPerStore(whichType) {
		db.getItems(whichType)
			.then(snapshot => {
				let noItems = false;
				if (snapshot.length === 0) noItems = true;
				this.setState({ stores: snapshot, noItemsInAnyStore: noItems, loading: false });
			})
			.catch(e => console.log("error", e));
	}
	snapshotToArray(snapshot) {
		var totalList = 0;
		var totalBid = 0;
		snapshot.forEach(function(childSnapshot) {
			var item = childSnapshot;
			totalList = totalList + Number(item.listPrice) || 0;
			totalBid = totalBid + Number(item.bidPrice) || 0;
		});
		return totalList - totalBid;
	}

	// Display page updated snack
	handleClickSnackSuccess = () => {
		this.setState({ openSuccess: false });
	};

	// Type change handler
	handleChange = (event, type) => {
		this.setState({ type });
		this.setState({ stores: [], loading: true });
		this.checkForUpdates(true);
	};
	handleChangeTextfield = name => event => {
		this.setState({ [name]: event.target.value });
	};

	changeName = () => {
		this.setState({ openName: true });
	};

	handleClickopenLearnMoreUser = () => {
		this.setState({ openLearnMoreUser: true });
	};

	handleClickopenRequest = () => {
		this.setState({ suggestedRetailer: this.state.suggestion });
		this.setState({ openRequest: true });
	};

	render() {
		const { classes, isMobile, curWidth } = this.props;
		const {
			openSuccess,
			openName,
			type,
			userName,
			stores,
			loading,
			noItemsInAnyStore,
			isNewUser,
			checkForUpdatesClicked,
			totalSaved
		} = this.state;

		return (
			<div
				style={isMobile ? { paddingTop: "70px" } : { paddingTop: "110px" }}
				className={classes.root}
			>
				<HelpModal
					openName={openName}
					isNewUser={isNewUser}
					userName={userName}
					handleClose={didChangeName => {
						if (didChangeName) {
							this.setState({ userName: didChangeName });
						}
						this.setState({ openName: false, isNewUser: false });
					}}
				/>

				<Grid container alignItems="center" spacing={3}>
					<Grid item xs={12} className={classes.itemGrid}>
						{!isMobile ? (
							<Grid container alignItems="center" spacing={0}>
								<Grid item xs={8}>
									<Grid
										container
										alignItems="center"
										spacing={3}
										style={{ textAlign: "left" }}
									>
										<Grid item xs={12}>
											<Tooltip title="Change name" placement="bottom-start">
												<Button className={classes.nameButton}>
													<Typography
														onClick={this.changeName}
														style={{ fontSize: "1.5em" }}
														className={classes.userName}
													>
														{userName}
													</Typography>
												</Button>
											</Tooltip>
											{curWidth > 630 ? (
												<Tooltip title="Check for Updates">
													<IconButton
														disabled={checkForUpdatesClicked}
														type="button"
														className={classes.buttonUpdate}
														onClick={() => this.checkForUpdates(false)}
													>
														<Update />
													</IconButton>
												</Tooltip>
											) : null}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={3} />
							</Grid>
						) : (
							<Grid container alignItems="center" spacing={0}>
								{/* <Grid item xs={1}/> */}
								<Grid
									item
									xs={11}
									style={{ padding: "0px", textAlign: "left", marginLeft: "1em" }}
								>
									<Button className={classes.nameButtonMobile}>
										<Typography
											onClick={this.changeName}
											variant="h6"
											className={classes.userName}
										>
											{userName}
										</Typography>
									</Button>
									<IconButton
										disabled={checkForUpdatesClicked}
										type="button"
										className={classes.buttonUpdate}
										onClick={() => this.checkForUpdates(false)}
									>
										<Update />
									</IconButton>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid container item xs={12} className={classes.itemGrid}>
						<Paper
							elevation={0}
							style={{
								display: "inline-flex",
								borderRadius: "10px",
								padding: "2em",
								textAlign: "center",
								margin: isMobile ? "-16px 16px" : null
							}}
						>
							<Grid item xs={6}>
								<img
									src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/youSaved.png"
									alt="YouSaved"
									style={{ height: 115 }}
								/>
							</Grid>
							<Grid item xs={6}>
								<h2 style={{ color: "#ffb2d1" }}>${totalSaved}</h2> saved shopping
								with enteroffer
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} className={classes.itemGrid}>
						<div
							style={{
								maxWidth: "100%",
								textAlign: isMobile ? "center" : null,
								marginTop: "1.5em"
							}}
						>
							<Tabs
								className={classes.tabsStyle}
								value={this.state.type}
								style={{
									width: isMobile ? "90%" : null
								}}
								TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
								onChange={this.handleChange}
								variant={isMobile ? "fullWidth" : null}
							>
								<Tab
									className={classes.tabStyle}
									style={{
										color: this.state.type !== 0 ? "#000" : "#FFF",
										backgroundColor:
											this.state.type !== 0 ? "transparent" : "#35B2FF"
									}}
									label="Pending"
								/>
								<Tab
									className={classes.tabStyle}
									style={{
										color: this.state.type !== 1 ? "#000" : "#FFF",
										backgroundColor:
											this.state.type !== 1 ? "transparent" : "#35B2FF"
									}}
									label="Approved"
								/>
								<Tab
									className={classes.tabStyle}
									style={{
										color: this.state.type !== 2 ? "#000" : "#FFF",
										backgroundColor:
											this.state.type !== 2 ? "transparent" : "#35B2FF"
									}}
									label="Rejected"
								/>
							</Tabs>
						</div>
					</Grid>
				</Grid>
				<Grid container alignItems="center" spacing={3}>
					<Grid item xs={12} className={classes.itemGrid}>
						<div>
							{_.size(stores) > 0 && !loading ? (
								<div>
									{!isMobile ? (
										<div>
											<Grid className={classes.storeRoot} item>
												<Store type={type} storeDetails={stores} />
											</Grid>
										</div>
									) : (
										<Grid container justify="center">
											<Grid item xs={12}>
												<div style={{ maxWidth: curWidth }}>
													<Grid className={classes.storeRoot} item>
														<Store type={type} storeDetails={stores} />
													</Grid>
												</div>
											</Grid>
										</Grid>
									)}
								</div>
							) : loading ? (
								<div style={{ paddingTop: "100px" }}>
									<Lottie
										height={200}
										width={200}
										options={{
											loop: true,
											autoplay: true,
											animationData: loader.default,
											rendererSettings: {
												preserveAspectRatio: "xMidYMid slice"
											}
										}}
									/>
								</div>
							) : !loading && noItemsInAnyStore ? (
								<div>
									<Grid container justify="center">
										<Grid item xs={12} style={{ textAlign: "center" }}>
											{type === 1 ? (
												<div style={{ paddingBottom: "50px" }}>
													<Typography
														className={classes.titleText}
														variant="h6"
													>
														You have no approved offers
													</Typography>
													Visit a partner store below to start making
													offers
													{/* <div className={classes.divMakeOffer}>
                                                <Typography variant="subtitle1" className={classes.textParagraph} >Visit our partner stores below to start making offers now</Typography>
                                                <HelpModal openLearnMoreUser={this.state.openLearnMoreUser} handleClose={() => this.setState({openLearnMoreUser: false})}/>
                                                <Button  onClick={this.handleClickopenLearnMoreUser} style={{fontFamily: 'Nunito Sans, sans-serif', textTransform: 'none'}}>Learn More<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '0.2em'}}/></Button>
                                            </div> */}
												</div>
											) : type === 0 ? (
												<div style={{ paddingBottom: "50px" }}>
													<Typography
														className={classes.titleText}
														variant="h6"
													>
														You have no pending offers!
													</Typography>
													Visit a partner store below to start making
													offers
													{/* <div className={classes.divMakeOffer}>
                                                <Typography variant="subtitle1" className={classes.textParagraph} >Visit our partner stores below to start making offers now</Typography>
                                                <HelpModal openLearnMoreUser={this.state.openLearnMoreUser} handleClose={() => this.setState({openLearnMoreUser: false})}/>
                                                <Button  onClick={this.handleClickopenLearnMoreUser} style={{fontFamily: 'Nunito Sans, sans-serif', textTransform: 'none'}}>Learn More<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '0.2em'}}/></Button>
                                            </div> */}
												</div>
											) : type === 2 ? (
												<div style={{ paddingBottom: "50px" }}>
													<Typography
														className={classes.titleText}
														variant="h6"
													>
														You have no rejected offers!
													</Typography>
													<Typography
														variant="subtitle1"
														className={classes.textParagraph}
													>
														{" "}
														Congratulations and keep making offers
													</Typography>
												</div>
											) : null}
										</Grid>
									</Grid>
								</div>
							) : null}
						</div>
					</Grid>
				</Grid>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={openSuccess}
					onClose={this.handleClickSnackSuccess}
					autoHideDuration={3000}
					message={<span id="message-id">Page Updated</span>}
				/>
				<br />
				<div
					style={{
						padding: isMobile ? "0em 1em" : "0em 5em",
						backgroundColor: "#fff",
						margin: isMobile ? null : "0em -5em"
					}}
				>
					<br />
					<Typography className={classes.userName}>
						<b>Stores</b>
					</Typography>
					<HelpModal
						openRequest={this.state.openRequest}
						retailer={this.state.suggestedRetailer}
						handleClose={() =>
							this.setState({
								openRequest: false,
								suggestedRetailer: "",
								suggestion: ""
							})
						}
					/>
					<Grid container spacing={2} style={{ marginTop: "1em" }}>
						<Grid item xs={isMobile ? 6 : 3}>
							<a
								href={"https://www.bausele.com/collections/pilot-automatic"}
								target="_blank"
								rel="noopener noreferrer"
								style={{ borderRadius: "0px", textDecoration: "none" }}
							>
								<div
									className={classes.liveStoresDiv}
									style={{
										height: isMobile ? "8em" : "16em",
										backgroundImage: `url('https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/bauseleStore.png')`
									}}
								></div>
								<p className={classes.liveStores}>Bausele</p>
							</a>
						</Grid>
						<Grid item xs={isMobile ? 6 : 3}>
							<a
								href={"https://www.azurarunway.com/"}
								target="_blank"
								rel="noopener noreferrer"
								style={{ borderRadius: "0px", textDecoration: "none" }}
							>
								<div
									className={classes.liveStoresDiv}
									style={{
										height: isMobile ? "8em" : "16em",
										backgroundImage: `url('https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/azuraRunwayStore.png')`
									}}
								></div>
								<p className={classes.liveStores}>Azura Runway</p>
							</a>
						</Grid>
						<Grid item xs={isMobile ? 6 : 3}>
							<a
								href={"https://meloxthelabel.com/collections/sale"}
								target="_blank"
								rel="noopener noreferrer"
								style={{ borderRadius: "0px", textDecoration: "none" }}
							>
								<div
									className={classes.liveStoresDiv}
									style={{
										height: isMobile ? "8em" : "16em",
										backgroundImage: `url('https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/meloxStore.jpeg')`
									}}
								></div>
								<p className={classes.liveStores}>Melox</p>
							</a>
						</Grid>
						<Grid item xs={isMobile ? 6 : 3}>
							<a
								href={"https://vaanicollection.com/collections/clearance"}
								target="_blank"
								rel="noopener noreferrer"
								style={{ borderRadius: "0px", textDecoration: "none" }}
							>
								<div
									className={classes.liveStoresDiv}
									style={{
										height: isMobile ? "8em" : "16em",
										backgroundImage: `url('https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/vaaniCollectionStore.png')`
									}}
								></div>
								<p className={classes.liveStores}>Vaani Collection</p>
							</a>
						</Grid>
						<Grid item xs={isMobile ? 12 : 3} style={{ textAlign: "center" }}>
							<a
								href="https://enteroffer.com/stores/"
								target="_blank"
								rel="noopener noreferrer"
								style={{ textDecoration: "none" }}
							>
								<Button
									variant="contained"
									className={classes.button}
									style={{ top: "33%" }}
								>
									See more stores
								</Button>
							</a>
						</Grid>
						<Grid item xs={12} style={{ marginTop: "1em" }}>
							{/* <Divider variant="fullWidth" /> */}
							<p style={{ textAlign: isMobile ? "center" : null }}>
								<b>Want to enter offers on more stores?</b>
							</p>
							<div style={{ marginBottom: "16px" }}>
								<TextField
									id="outlined-name"
									placeholder="  Suggest a store..."
									value={this.state.suggestion}
									onChange={this.handleChangeTextfield("suggestion")}
									autoComplete="off"
									margin="normal"
									style={{
										textAlign: "left",
										width: isMobile ? "100%" : "30%",
										fontSize: "1em",
										backgroundColor: "#f6f6f8",
										border: "1px solid #92929244",
										borderRadius: "5px",
										margin: 0,
										marginRight: isMobile ? null : "8px",
										marginBottom: isMobile ? 8 : null
									}}
									required={true}
									InputProps={{
										disableUnderline: true,
										style: {
											fontSize: "1em",
											fontFamily: "Nunito Sans",
											display: "block"
										}
									}}
								/>
								<Button
									variant="contained"
									fullWidth={isMobile}
									className={classes.button}
									onClick={this.handleClickopenRequest}
								>
									Submit
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Home));
