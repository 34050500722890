import { auth, firebase } from "./";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
	auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
	auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password =>
	auth.currentUser.updatePassword(password);

export const deleteUser = (email, password) => {
	const credential = firebase.auth.EmailAuthProvider.credential(
		email,
		password
	);
	var user = auth.currentUser;
	return new Promise((resolve, reject) => {
		user.reauthenticateWithCredential(credential)
			.then(() => {
				console.log("REAUTHED");
				return firebase.database.ref(`/users/${user.uid}`).remove();
			})
			.then(() => {
				return resolve(user.delete());
			})
			.then(() => {
				console.log("GONE");
			})
			.catch(error => {
				return reject(error);
				// An error happened.
			});
	});
};
