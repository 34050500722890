import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { db } from "../firebase";

const styles = theme => ({});

class SettingsModal extends Component {
	state = {
		confirmPopup: false
	};

	handleChange = event => {
		this.setState({ confirmPopup: event.target.checked });
		if (event.target.checked) {
			db.setUserConfirmPref(1);
		} else {
			db.setUserConfirmPref(0);
		}
	};

	handleClose = () => {
		this.props.handleClose("apply");
	};

	handleCloseCancel = () => {
		this.props.handleClose("cancel");
	};

	componentDidMount() {
		db.getUserConfirmPref()
			.then(snapshot => {
				if (snapshot === 1) {
					this.setState({ confirmPopup: true });
				} else {
					this.setState({ confirmPopup: false });
				}
			})
			.catch(e => console.log("error", e));
	}

	render() {
		return (
			<div>
				<Dialog
					open={this.props.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Settings"}
					</DialogTitle>
					<DialogContent>
						<h2
							style={{
								fontSize: "1em",
								fontWeight: "400",
								color: "#7f7f7f",
								marginTop: "0px",
								marginBottom: "40px"
							}}
						>
							Turning on confirmation popup will mean that
							accepting or rejecting offers will require you to
							confirm your decision a second time.
						</h2>
						{/* <DialogContentText id="alert-dialog-img">
                        </DialogContentText> */}
						<FormControl
							component="fieldset"
							style={{ display: "block", textAlign: "center" }}
						>
							{/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
							<FormGroup style={{ display: "block" }}>
								{/* <FormHelperText>Pop up a window when approve/reject an offer</FormHelperText> */}
								<FormControlLabel
									control={
										<Switch
											checked={this.state.confirmPopup}
											onChange={this.handleChange}
											value="confirmPopup"
											color="primary"
										/>
									}
									label="Confirmation Popup"
								/>
							</FormGroup>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} autoFocus>
							Done!
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(SettingsModal);
