import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	}
});

class EditMenu extends React.Component {
	state = {
		open: false,
		newOffer: ""
	};

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
		console.log(this.state.newOffer);
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		console.log(this.props);

		return (
			<div
				style={{
					height: "1em",
					display: "inline-block",
					fontFamily: "Nunito Sans, sans-serif"
				}}
			>
				<IconButton onClick={this.handleClickOpen}>
					<EditIcon style={{ color: "#35B2FF" }} />
				</IconButton>
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					open={this.state.open}
					onClose={this.handleClose}
				>
					<DialogTitle>Enter your new offer</DialogTitle>
					<DialogContent>
						<TextField
							id="outlined-name"
							placeholder="Enter new offer"
							value={this.state.newOffer}
							onChange={this.handleChange("newOffer")}
							autoComplete="off"
							margin="normal"
							style={{
								display: "inline-block",
								textAlign: "center",
								width: "100%",
								fontSize: "1em",
								backgroundColor: "#f6f6f8",
								borderRadius: "0.2em"
							}}
							required={true}
							InputProps={{
								disableUnderline: true,
								style: {
									fontSize: "1em",
									fontFamily: "Nunito Sans",
									textAlign: "center"
								}
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleClose} color="primary">
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

EditMenu.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditMenu);
