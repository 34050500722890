import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withSizes from 'react-sizes';
import _ from 'lodash';
import Fade from 'react-reveal/Fade';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    image: {
        height: '130px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        position: 'relative',
        width: '100%'
    },
    textFieldDropdown: {
        backgroundColor: '#f6f6f8',
        borderRadius: '4px'
    },
    input: {
        padding: '0.3em',
        fontWeight: '700'
    },
    inputLoc: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '2.5em'
    },
    inputDrop: {
        marginLeft: ' -4px',
        padding: '0.2em 0.8em 0.2em 0em',
        fontWeight: '700',
        fontSize: '0.8em'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#f6f6f8 !important'
    },
    adornment: {
        fontSize: '1.8em'
    },
    icon: {
        verticalAlign: 'top',
        fontSize: '1.3em'
    },
    button: {
        color: '#656565',
        marginLeft: '1em'
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

function Receipt(props) {
    const classes = useStyles();
    const [data, setData] = useState(props.offer);
    
    return (
        <div>
            <Grid
                container
                justify='center'
                style={{
                    marginTop: '1em',
                    width: '100%',
                    fontFamily: "Nunito Sans, sans-serif",
                }}
            >
                <Fade>
                    <Grid
                        item
                        xs={12}
                        style={{ margin: 'auto' }}
                    >
                        <Paper
                            elevation={0}
                            style={{
                                padding: '1em 0em',
                                textAlign: 'center',
                                boxShadow:
                                    '0 1px 6px rgba(0,0,0,0.16), 0 1px 6px rgba(0,0,0,0.23)',
                                borderRadius: '2em'
                            }}
                        >
                            <p
                                style={{
                                    textAlign: 'center',
                                    color: '#929292'
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: 800,
                                        color: '#000',
                                        fontSize: '1.2em'
                                    }}
                                >
                                    OFFER RECEIPT
                                </span><br />
                                Offer ID: {String(data.bidKey)}
                            </p>
                            <Divider />
                            {data &&
                                _.map(data.products, (product, key) => (
                                    <Grid
                                        container
                                        key={key}
                                        style={{
                                            padding: '1em 2em',
                                            textAlign: 'left',
                                            fontFamily: "Nunito Sans, sans-serif",
                                        }}
                                    >
                                        <Grid item container xs={4}>
                                            <div
                                                style={{
                                                    height: '150px',
                                                    width: '90%',
                                                    border: '1px solid #f6f6f8'
                                                }}
                                            >
                                                <ReactCSSTransitionGroup
                                                    transitionName='loadingItem'
                                                    transitionAppear={true}
                                                    transitionAppearTimeout={
                                                        500
                                                    }
                                                    transitionEnterTimeout={500}
                                                    transitionLeaveTimeout={300}
                                                >
                                                    <img
                                                        className={classes.img}
                                                        src={product.image}
                                                        alt={product.productName}
                                                    />
                                                </ReactCSSTransitionGroup>
                                            </div>
                                        </Grid>
                                        <Grid item xs={8} container>
                                            <Grid item xs={12} container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        paddingBottom: '0em',
                                                        paddingRight: '0em',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontFamily: "Nunito Sans, sans-serif",
                                                            fontSize: props.isMobile
                                                                ? '0.9em'
                                                                : '1.1em',
                                                            fontWeight: '700',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                            WebkitLineClamp:
                                                                '2',
                                                            WebKitBoxOrient:
                                                                'vertical'
                                                        }}
                                                    >
                                                        {product.title}
                                                    </Typography>
                                                    <Typography
                                                        style={{fontFamily: "Nunito Sans, sans-serif",
                                                            fontSize: '0.9em',
                                                            fontWeight: '700',
                                                            color: '#929292',
                                                            margin:
                                                                '-3px auto 5px'
                                                        }}
                                                    >
                                                        {`${
                                                            data.currency
                                                        } ${
                                                            data.currencySign
                                                        }${Number(
                                                            product.listPrice
                                                        ).toFixed(2)}`}
                                                    </Typography>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            xs={
                                                                props.isMobile
                                                                    ? 4
                                                                    : 3
                                                            }
                                                            container
                                                        >
                                                            <TextField
                                                                id={`outlined-select-${product.variantID}`}
                                                                // select
                                                                disabled
                                                                className={
                                                                    classes.textFieldDropdown
                                                                }
                                                                value={
                                                                    product.quantity
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '4px'
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        input:
                                                                            classes.inputDrop,
                                                                        notchedOutline:
                                                                            classes.notchedOutline
                                                                    },
                                                                    startAdornment: (
                                                                        <InputAdornment position='start'>
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '0.8em',
                                                                                    color:
                                                                                        '#929292',
                                                                                    fontWeight:
                                                                                        '700'
                                                                                }}
                                                                            >
                                                                                QTY
                                                                            </span>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                variant='outlined'
                                                            >
                                                                ...
                                                            </TextField>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={
                                                                props.isMobile
                                                                    ? 8
                                                                    : 9
                                                            }
                                                            container
                                                        >
                                                            <TextField
                                                                id={`outlined-variants-${product.variantID}`}
                                                                // select
                                                                disabled
                                                                className={
                                                                    classes.textFieldDropdown
                                                                }
                                                                value={
                                                                    product.variantName
                                                                }
                                                                style={{
                                                                    fontFamily: "Nunito Sans, sans-serif",
                                                                    width:
                                                                        '100%'
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        input:
                                                                            classes.inputDrop,
                                                                        notchedOutline:
                                                                            classes.notchedOutline
                                                                    },
                                                                    startAdornment: (
                                                                        <InputAdornment position='start'>
                                                                            <span
                                                                                style={{fontFamily: "Nunito Sans, sans-serif",
                                                                                    fontSize:
                                                                                        '0.8em',
                                                                                    color:
                                                                                        '#929292',
                                                                                    fontWeight:
                                                                                        '700'
                                                                                }}
                                                                            >
                                                                                Option
                                                                            </span>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                variant='outlined'
                                                            >
                                                                ...
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                    {data.cartAbandoned ? null : (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                        >
                                                            <Typography
                                                                style={{fontFamily: "Nunito Sans, sans-serif",
                                                                    fontSize: props.isMobile
                                                                        ? '0.9em'
                                                                        : '1.1em',
                                                                    margin:
                                                                        '1em 0em'
                                                                }}
                                                            >
                                                                Your Offer:
                                                                <br />
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '700'
                                                                    }}
                                                                >
                                                                    {`${
                                                                        data.currency
                                                                    } ${
                                                                        data.currencySign
                                                                    }${Number(
                                                                        product.bidPrice
                                                                    ).toFixed(
                                                                        2
                                                                    )}`}
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Divider />
                            <p style={{ margin: '1em 2em', textAlign: 'left' }}>
                                Cart Total:
                                <span
                                    style={{ fontWeight: 700, float: 'right' }}
                                >
                                    {`${data.currency} ${
                                        data.currencySign
                                    }${(Number(data.bidPrice)).toFixed(2)}`}
                                </span>
                            </p>
                            <p style={{ margin: '1em 2em', textAlign: 'left' }}>
                                {data.shipping.name}:
                                <span
                                    style={{ fontWeight: 700, float: 'right' }}
                                >
                                    {`${data.currency} ${
                                        data.currencySign
                                    }${Number(data.shippingPrice).toFixed(
                                        2
                                    )}`}
                                </span>
                            </p>
                            <p style={{ margin: '1em 2em', textAlign: 'left' }}>
                                Offer Total:
                                <span
                                    style={{ fontWeight: 700, float: 'right' }}
                                >
                                    {`${data.currency} ${
                                        data.currencySign
                                    }${(Number(data.bidPrice)+Number(data.shippingPrice)).toFixed(2)}`}
                                </span>
                            </p>
                            <Divider />

                            <p
                                style={{
                                    color: '#929292',
                                    margin: '1em 2em 3em',
                                    textAlign: 'left'
                                }}
                            >
                                If accepted your order will be shipped to:
                                <br />
                                <br />
                                <span
                                    style={{ fontWeight: 700, color: '#000' }}
                                >
                                    {data.shippingAddress.company !== ''
                                        ? data.shippingAddress.company
                                        : null}
                                    {data.shippingAddress.company !== '' ? (
                                        <br />
                                    ) : null}
                                    {data.shippingAddress.line1}
                                    <br />
                                    {data.shippingAddress.city}
                                    <br />
                                    {data.shippingAddress.state}{' '}
                                    {data.shippingAddress.address_zip}
                                    <br />
                                    {data.shippingAddress.country}
                                </span>
                            </p>
                        </Paper>
                        </Grid>
                </Fade>
            </Grid>
        </div>
    );
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 900
});

export default withSizes(mapSizesToProps)(Receipt);
