import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Toolbar, AppBar, Grid } from "@material-ui/core";
import { HelpModal } from "./index";
import withSizes from "react-sizes";
import moment from "moment";

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.primary.dark,
		boxShadow: "none",
		flexGrow: 1,
		borderBottom: "1px solid grey",
		background: `#fff`,
		display: "flex",
		minHeight: "100vh",
		flexDirection: "column",
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "space-between",
	},
	appBar: {
		top: "auto",
		bottom: 0,
		position: "relative",
		padding: "0px -8px -8px 8px",
		// borderTop: '1px solid grey',
		background: `#F6F6F8`,
		boxShadow: "none",
	},
	button: {
		color: "#656565",
	},
	logo: {
		width: "150px",
		height: "100%",
		maxWidth: "100%",
	},
});

class BottomNav extends React.Component {
	state = {
		openTerms: false,
		openPrivacy: false,
	};

	handleClickOpenTerms = () => {
		this.setState({ openTerms: true });
	};

	handleClickOpenPrivacy = () => {
		this.setState({ openPrivacy: true });
	};

	render() {
		const { classes, isMobile } = this.props;

		return (
			<AppBar color="primary" className={classes.appBar}>
				<HelpModal
					openTerms={this.state.openTerms}
					openPrivacy={this.state.openPrivacy}
					handleClose={() => this.setState({ openPrivacy: false, openTerms: false })}
				/>
				<div
					style={{
						backgroundColor: "#3F414C",
						color: "#FFF",
						width: "100vw",
						marginLeft: "calc(-50vw + 50%)",
					}}
				>
					<Grid
						container
						direction="row"
						// xs={isMobile ? 12 : 8}
						justify="flex-start"
						alignItems="flex-start"
						style={{ padding: "1em 2em" }}
					>
						<Grid item xs={isMobile ? 6 : 4}>
							<p>
								<b>EnterOffer</b>
							</p>
							<p style={{ lineHeight: "1.8" }}>
								<a
									href="https://enteroffer.com/about/"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									About Us
								</a>
								<br />
								<span
									style={{ cursor: "pointer" }}
									onClick={this.handleClickOpenTerms}
								>
									Term of Use
								</span>
								<br />
								<span
									style={{ cursor: "pointer" }}
									onClick={this.handleClickOpenPrivacy}
								>
									Privacy Policy
								</span>
								<br />
							</p>
						</Grid>
						<Grid item xs={isMobile ? 6 : 4}>
							<p>
								<b>Help & Support</b>
							</p>
							<p style={{ lineHeight: "1.8" }}>
								<a
									href="https://enteroffer.com/faq"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									FAQ
								</a>
								<br />
								<a
									href="https://enteroffer.com/#contactUs"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Contact Us
								</a>
								<br />
							</p>
						</Grid>

						<Grid item xs={isMobile ? 6 : 4}>
							<p>
								<b>Follow Us</b>
							</p>
							<p style={{ lineHeight: "1.8" }}>
								<a
									href="https://www.instagram.com/enteroffer/"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Instagram
								</a>
								<br />
								<a
									href="https://www.facebook.com/enteroffer/"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Facebook
								</a>
								<br />
								<a
									href="https://twitter.com/EnterOffer"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Twitter
								</a>
								<br />
							</p>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						backgroundColor: "#F6F6F8",
						width: "100vw",
						marginLeft: "calc(-50vw + 50%)",
						padding: "0px -8px -8px 8px",
					}}
				>
					<Toolbar className={classes.toolbar}>
						<div>
							<a href="https://enteroffer.com/">
								<img
									src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
									alt="enterOffer"
									style={{
										width: isMobile ? "7em" : "9em",
										display: "block",
										marginLeft: "auto",
										marginRight: "auto",
									}}
								/>
							</a>
						</div>
						<div>
							<p
								className={classes.button}
								style={{
									fontWeight: 300,
									fontSize: isMobile ? "0.75em" : null,
								}}
							>
								Copyright &copy; {moment().year()} EnterOffer
							</p>
						</div>
					</Toolbar>
				</div>
			</AppBar>
		);
	}
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(withStyles(styles)(BottomNav));
